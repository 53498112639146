<template>
  <div class="about">
    <sHeader :hasBack="true" :title="'教学资源'"></sHeader>
    <nBar :userType="'student'" :activeItemName="'index'"></nBar>
    <support :otherClass="'width40'" />

    <div class="pageContent">
      <div class="modular-base">
        <div class="modular-item">
          <CaseBasInfo :caseInfo="caseInfo"></CaseBasInfo>
        </div>
        <div class="modular-item">
          <ul class="caseResList" v-if="resource.length">
            <li
              class="resItem"
              v-for="(item, index) in resource"
              :class="current == index ? 'curren' : ''"
              @click="lookRes(index, item.object_url, item.ext, item.id)"
            >
              <div class="iconBox">
                <van-image
                  v-if="item.ext == 'mp3'"
                  width="70px"
                  height="70px"
                  :src="
                    current == index
                      ? require('@/assets/images/curAudio.png')
                      : require('@/assets/images/audio.png')
                  "
                />
                <van-image
                  v-if="item.ext == 'mp4'"
                  width="70px"
                  height="70px"
                  :src="
                    current == index
                      ? require('@/assets/images/curVideo.png')
                      : require('@/assets/images/video.png')
                  "
                />
                <van-image
                  v-if="item.ext !== 'mp3' && item.ext !== 'mp4'"
                  width="70px"
                  height="70px"
                  :src="
                    current == index
                      ? require('@/assets/images/curFile.png')
                      : require('@/assets/images/file.png')
                  "
                />
              </div>
              <div class="studyInfo">
                <h3 style="margin: 0">{{ item.name }}</h3>
                <ul class="studyState">
                  <li>
                    <div class="title">学习时长</div>
                    <div>
                      {{ secondsToIS(item.student_learning_duration).chinese }}
                    </div>
                  </li>
                  <li>
                    <div class="title">学习状态</div>
                    <div>{{ item.status ? "已学习" : "待学习" }}</div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="modular-content">
        <!-- {{ prefix(object_url) }} -->
        <div class="modular-item">
          <div class="nothing" v-if="!resource.length">
            <van-empty
              :image="require('@/assets/images/kong.png')"
              image-size="80"
              description="没有找到相关资源"
            />
          </div>
          <div
            class="content"
            :class="ext == 'mp3' ? 'isAudio' : ''"
            v-if="resource.length"
          >
            <iframe
              v-if="ext !== 'mp3' && ext !== 'mp4'"
              :src="
                fileType.includes(ext)
                  ? prefix(object_url)
                  : prefixFile(prefix(object_url))
              "
              frameborder="0"
              width="100%"
              height="100%"
              style="border: 1px solid #eee"
              >内容加载中</iframe
            >
            <audio
              :src="prefix(object_url)"
              v-if="ext == 'mp3'"
              controls
              @playing="audioPlayIng"
              @pause="pauseFn"
              @ended="audioEnd"
            ></audio>
            <video
              :src="prefix(object_url)"
              controls="controls"
              v-if="ext == 'mp4'"
              @play="videoPlay"
              @pause="viPause"
              @ended="videoEnd"
            ></video>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onBeforeMount } from "vue";
import { useRoute, onBeforeRouteLeave } from "vue-router";
import sHeader from "@/components/SimpleHeader";
import nBar from "@/components/NavBar";
import support from "@/components/Support";
import CaseBasInfo from "@/views/teacher/components/CaseBasInfo.vue";
import {
  apiCasesResourceList,
  apiSaveStudyTime,
  apiGetCaseInfo,
} from "@/service/student.js";
import { secondsToIS, prefix, prefixFile } from "@/common/js/utils.js";

const route = useRoute();
const userInfo = JSON.parse(localStorage.getItem("userInfo"));

let current = ref(0);
let fileType = ref(["pdf", "png", "jpg", "jpeg", "txt"]);

let resource = ref([]);
let object_url = ref("");
let ext = ref("");
let caseSourceId = ref(0);
let studyTime = ref(0);
let subIndex = ref(0);

onBeforeRouteLeave(() => {
  window.clearInterval(timer);
  window.clearInterval(readeTimer);
  window.clearInterval(activeMp3Timer);
  window.clearInterval(activeReadeTimer);
  window.clearInterval(activeViTimer);
});

let caseInfo = ref({});
// 获取学案信息
const getCaseInfo = async () => {
  let res = await apiGetCaseInfo({ caseId: route.query.case_id });
  if (res) {
    caseInfo.value = res.data;
  }
};

// 获取资源列表
const getCasesResourceList = async () => {
  let res = await apiCasesResourceList({
    case_id: route.query.case_id,
    studentsNo: userInfo.studentsNo,
  });
  if (res && res.data.length) {
    resource.value = res.data;
    ext.value = res.data[0].ext;
    object_url.value = res.data[0].object_url;
    caseSourceId.value = res.data[0].id;
    studyTime.value = res.data[0].student_learning_duration;
  }
};

onBeforeMount(async () => {
  await getCasesResourceList();
  await getCaseInfo();
});

// 查看资源列表
let readeTimer = null;
let activeReadeTimer = null;
const lookRes = (index, url, type, id) => {
  current.value = index;
  object_url.value = url;
  ext.value = type;
  caseSourceId.value = id;
  subIndex.value = index;
  studyTime.value = 0;

  if (ext.value !== "map3" && ext.value !== "mp4") {
    window.clearInterval(readeTimer);
    window.clearInterval(activeReadeTimer);

    activeReadeTimer = window.setInterval(() => {
      resource.value[subIndex.value].student_learning_duration += 1;
    }, 1000);
    readeTimer = window.setInterval(async () => {
      studyTime.value += 5;
      let res = await saveStudyTime();
      if (res) {
      }
    }, 10000);
  }
  if (ext.value == "mp3" || ext.value == "mp4") {
    window.clearInterval(readeTimer);
    window.clearInterval(activeReadeTimer);
  }
};

//保存学习时长
const saveStudyTime = async () => {
  let formData = new FormData();
  formData.append("case_id", route.query.case_id);
  formData.append("caseSourceId", caseSourceId.value);
  formData.append("studentsNo", userInfo.studentsNo);
  formData.append(
    "learning_duration",
    resource.value[subIndex.value].student_learning_duration
  );
  let res = await apiSaveStudyTime(formData);
  if (res) {
    return res;
  }
};

// 音频播放触发的方法
let timer = null;
let activeMp3Timer = null;
const audioPlayIng = () => {
  activeMp3Timer = window.setInterval(() => {
    resource.value[subIndex.value].student_learning_duration += 1;
  }, 1000);
  timer = window.setInterval(async () => {
    studyTime.value += 5;
    let res = await saveStudyTime();
  }, 10000);
};

// 音频暂停触发的方法
const pauseFn = () => {
  window.clearInterval(timer);
  window.clearInterval(activeMp3Timer);
};

// 音频播放结束时触发
const audioEnd = () => {
  saveStudyTime();
  clearInterval(activeMp3Timer);
  clearInterval(timer);
};

// 视频播放触发
let viTimer = null;
let activeViTimer = null;
const videoPlay = () => {
  activeViTimer = window.setInterval(() => {
    resource.value[subIndex.value].student_learning_duration += 1;
  }, 1000);
  viTimer = window.setInterval(async () => {
    studyTime.value += 5;
    let res = await saveStudyTime();
  }, 10000);
};

// 视频暂停
const viPause = () => {
  window.clearInterval(viTimer);
  window.clearInterval(activeViTimer);
};

// 视频播放结束时触发
const videoEnd = () => {
  saveStudyTime();
  clearInterval(activeViTimer);
  clearInterval(viTimer);
};
</script>

<style lang="less" scoped>
@import "@/common/style/caseList.less";

.caseResList {
  padding: 5px;
  background-color: #fff;
  border-radius: 10px;

  li:nth-last-child(1) {
    border-bottom: none;
  }
}
.resItem {
  display: flex;
  border-bottom: 1px solid #eee;
  margin-bottom: 5px;
  padding-bottom: 5px;

  .iconBox {
    margin-right: 10px;
  }

  .studyInfo {
    .studyState {
      margin-top: 10px;
      font-size: 10px;
      display: flex;
      li {
        margin-right: 20px;
      }
    }
  }
}
.curren {
  color: rgb(18, 150, 219) !important;
}

.content {
  height: 90vh;
  // width: 100%;
  position: relative;

  video {
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-60%);
    left: 0;
    object-fit: cover;
  }
}

.isAudio {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
