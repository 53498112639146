import request from "@/utils/request";
import requestForm from "@/utils/requestForm";

// 获取学生对应学年列表
export function academicYears() {
  return request.get("/h5/student/academicYears");
}

export function apiGetCasesList(params) {
  return request.get("/h5/student/caseList", { params });
}

export function apiGetCaseInfo(params) {
  return request.get("/h5/student/caseInfo", { params });
}

export function apiGetCaseQuestions(params) {
  return request.get("/h5/student/caseQuestions", { params });
}

export function apiPostAnswer(params) {
  return requestForm.post("/h5/student/postAnswer", params);
}

export function apiPostAnswer2(params) {
  return requestForm.post("/h5/student/postAnswer2", params);
}

export function apiFinishCase(params) {
  return requestForm.post("/h5/student/finishCase", params);
}

export function apiFollowQuestion(params) {
  return requestForm.post("/h5/student/followQuestion", params);
}

// 错题列表
export function apiGetWrongList(params) {
  return request.get("/h5/student/wrongQuestionList", { params });
}

/*
  param = {
    id: 列表返回的ID
  }
*/
export function apiGetWrongInfo(params) {
  return request.get("/h5/student/wrongQuestionInfo", { params });
}

/* 错题已理解
  params = {
    *ids（内容 1,2,3,4,5）
  }
*/
export function apiWrongToUnderstand(params) {
  return requestForm.post("/h5/student/wrongToUnderstand", params);
}

/**学生保存自我评价
 * params = {
 *  case_id*（学案id）,*evaluate（评价内容）,*image_path（附加图片的路径）
 * }
 */
export function apiEval(params) {
  return requestForm.post("h5/studentEvaluate/caseEvaSave", params);
}

/**查询自我评价详情
 * params={
 *  *case_id(学案ID)
 * }
 */
export function apiCaseEvaInfo(params) {
  return request.get("h5/studentEvaluate/caseEvaInfo", { params });
}

/**保存主观题备注
 * params = {
 *  *case_id(学案ID),
 *  *question_id(问题ID),
 *  *remarks(备注),
 *  *image_path(图片)
 * }
 */
export function apiSaveQuestionRemarks(params) {
  return requestForm.post("h5/student/saveQuestionRemarks", params);
}

/**查询主观题备注详情
 * params = {
 *  *case_id(学案ID), *question_id(问题ID)
 * }
 */
export function apiStudentRemarksInfo(params) {
  return request.get("h5/student/questionRemarksInfo", { params });
}

/**获取资源列表
 * params = {
 *  case_id: 1, //必传
		studentsNo: 1 //必传，学生账号 
 * }
 */
export function apiCasesResourceList(params) {
  return request.get("/h5/student.CasesResource/lists", { params });
}

/**保存学习时长
 * params = {
 *  	case_id: 1, //必传
			caseSourceId: 1, //必传，资源ID
			studentsNo: 1 //必传，学生账号
			learning_duration: 1 //必传，学习时长（单位秒）
 * }
 */
export function apiSaveStudyTime(params) {
  params.notShowToast = true;
  return requestForm.post("/h5/student.CasesResource/saveLearningTime", params);
}

/**获取首页数据统计 */
export function apiGetIndexData() {
  return request.get("/h5/student.index/statistics");
}

/**查看老师评阅
 * 参数：
​			{
​				caseId: 1,
​			}
 */
export function apiGetTeacherRemark(params) {
  return request.get("/h5/student.casesReview/teacherReview", { params });
}

/**获取学案状态 */
export function apiGetCaseStatus() {
  return request.get("h5/student.casesStatus/lists");
}

/**错题本科目列表 */
export function apiGetWrongSubjectLists() {
  return request.get("/h5/student.wrongBook/subjectLists");
}

/**错题本题目类型 */
export function apiGetWrongTypeLists() {
  return request.get("/h5/student.wrongBook/typeLists");
}

/**错题列表
 * 参数：
		{
			page: 1,
			limit: 10,
			subjectId: 1,
			typeId: 1,
		}
 */
export function apiGetWrongBookList(params) {
  return request.get("/h5/student.wrongBook/lists", { params });
}

/**学情分析 学习投入 */
export function apiGetInputTime() {
  return request.get("/h5/student.analysis/investment");
}

/**学情分析 科目列表
 * 参数：
		{
			gradeId: 1
		}
 */
export function apiGetSisSubject(params) {
  return request.get("/h5/subject/lists", { params });
}

/**根据科目统计学习投入
 * 参数：
		{
			subjectId: 1
		}
 */
export function apiGetInputForSubject(params) {
  params.notShowToast = true;
  return request.get("/h5/student.analysis/investmentForSubject", { params });
}

/**学情分析 根据科目统计
 * 参数：
		{
			subjcetId: 1,
			page: 1,
			limit: 10
		}
 */
export function apiGetTableData(params) {
  return request.get("/h5/student.analysis/casesForSubject", { params });
}

/**学情分析echarts图表 */
export function apiGetEcheatsData() {
  return request.get("/h5/student.analysis/echarts");
}

/**学生查询题目里老师的批改
 * answer_id
 */
export function apiGetCorrection(params) {
  return request.get("/h5/Student/teacherCorrection", { params });
}
